<template>
  <!-- 管理员管理 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="container">
      <div class="right">
        <div class="header-search">
          <el-form ref="form" inline label-width="">
            <el-form-item label="管理员姓名">
              <el-input style="width: 220px" v-model.trim="searchData.username" size="small" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input style="width: 220px" v-model.trim="searchData.phone" size="small" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="角色">
              <SelectRole v-model="searchData.role_id" width="220px"></SelectRole>
            </el-form-item>
            <el-form-item label="显示范围">
              <el-select v-model="searchData.book" placeholder="请选择" size="small">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="只看通讯录" :value="1"></el-option>
                <el-option label="只看管理员" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" ">
              <el-button type="primary" size="small" icon="el-icon-search" @click="getAdminList">搜索</el-button>
              <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-divider></el-divider>

        <div class="top-btn">
          <el-button
            v-checkbtn="isShowBtn(AUTH_BTN.admin_list_add)"
            style="margin-bottom: 20px"
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click="$router.push({ name: 'PowerManageUserAdd' })"
            >新增</el-button
          >
          <!-- <el-button
            v-checkbtn="isShowBtn(AUTH_BTN.admin_list_add)"
            style="margin-bottom: 20px"
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click="handleGroupCall"
            >发起通话</el-button
          > -->
        </div>
        <UserTable
          :isNeedSerialNumber="true"
          :tableData="list"
          :columns="columns"
          :totalNum="total"
          :current-page.sync="currentPage"
          :pageSize.sync="pageSize"
          :isCheckBox="false"
          @handleSelectionChange="handleSelectionChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectRole from '@/views/components/select-role.vue'
import { getAdminList, removeAdmin, changeStatus } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import UserTable from './table.vue'
import { mapState } from 'vuex'
const columns = [
  // {
  //   label: 'ID',
  //   prop: 'id',
  //   minWidth: '100',
  //   customRender(h, row) {
  //     return <p>{row['id']}</p>
  //   }
  // },
  {
    label: '登录账号',
    prop: 'account',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['account']}</p>
    }
  },
  {
    label: '姓名',
    prop: 'username',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['username']}</p>
    }
  },
  {
    label: '手机号',
    minWidth: '150',
    prop: 'phone',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['phone']}>
          {row['phone']}
        </p>
      )
    }
  },
  {
    label: '角色',
    minWidth: '120',
    prop: 'user_role',
    customRender(h, row) {
      return <div>{this.handleRole(row['user_role'])}</div>
    }
  },
  {
    label: '显示范围',
    width: '120',
    prop: 'user_role',
    customRender(h, row) {
      return (
        <div>
          <el-tag size="small" v-show={row.is_address_book == 0} type="primary">
            管理员
          </el-tag>
          <el-tag size="small" v-show={row.is_address_book == 1} type="warning">
            通讯录
          </el-tag>
        </div>
      )
    }
  },

  {
    label: '状态',
    prop: 'status',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          <el-switch
            class="switch"
            onChange={() => this.changeStatus(row)}
            disabled={row['is_super'] == 1 || !this.isShowBtn(this.AUTH_BTN.admin_list_status)}
            v-model={row['status']}
            active-value={20}
            inactive-value={10}
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </div>
      )
    }
  },
  {
    label: '创建时间',
    minWidth: '180',
    prop: 'created_at',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['created_at']}>
          {row['created_at']}
        </p>
      )
    }
  },
  {
    label: '操作',
    fixed: 'right',
    minWidth: '150',
    customRender(h, row) {
      return (
        <div>
          <el-link
            style="margin:0 5px"
            v-show={this.isShowBtn(this.AUTH_BTN.admin_list_edit)}
            disabled={false}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'PowerManageUserEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            style="margin:0 5px"
            v-show={this.isShowBtn(this.AUTH_BTN.admin_list_del)}
            disabled={row['is_super'] == 1}
            underline={false}
            type="primary"
            onClick={() => this.goRemove(row['id'])}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { SelectRole, UserTable },
  data() {
    return {
      list: [],
      pageSize: 10,
      total: 0,
      currentPage: 1,
      columns,
      searchData: {
        username: '',
        phone: '',
        role_id: '',
        book: 0
      },
      select_user_ids: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user
    })
  },
  watch: {
    currentPage() {
      this.getAdminList()
    },
    pageSize() {
      this.getAdminList()
    }
  },

  mounted() {
    this.getAdminList()
  },

  methods: {
    goChat({ tim_userid }) {
      this.$bus.$emit('chat', tim_userid)
    },
    async getAdminList() {
      const { username, phone, role_id, book } = this.searchData
      let params = { username, phone, role_id, page: this.currentPage, page_size: this.pageSize }
      if (book == 1) {
        params.is_address_book = 1
      }
      if (book == 2) {
        params.is_address_book = 0
      }
      let res = await getAdminList(params)
      this.list = res?.data || []
      this.total = res?.total || 0
    },

    async goRemove(id) {
      this.$confirm('此操作将永久删除该管理员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          removeAdmin(id).then(() => {
            this.$message.success('删除成功')
            this.getAdminList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getAdminList()
    },
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status == 10 ? 10 : 20 }
      let statusDesc = status == 10 ? '禁用' : '启用'

      this.$confirm(`此操作将该管理员${statusDesc}吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status == 10 ? 10 : 20
          changeStatus(data).then(() => {
            this.getAdminList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status == 20 ? 10 : 20
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    handleRole(arr) {
      arr = arr.map((item) => {
        return item.role_name
      })
      return arr.join(',') || '-'
    },
    handleGroupCall() {
      if (this.select_user_ids.length == 0) return this.$message.warning('请选择通话成员')
      this.$bus.$emit('chat', this.select_user_ids)
    },
    handleSelectionChange(val) {
      this.select_user_ids = val.map((item) => item.tim_userid)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .top-btn {
    display: flex;
    justify-content: space-between;
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.wrapper {
  .container {
    // display: flex;
    // height: calc(100% - 23px);
    .left {
      border-right: 1px solid #ccc;
      margin-right: 20px;
      .el-cascader {
        min-width: 200px;
        max-width: 300px;
        margin: 10px;
      }
      .tree {
        min-width: 200px;
        max-width: 300px;
        .el-tree /deep/ {
          .el-tree-node__content {
            height: 40px;
            .el-tree-node__expand-icon {
              margin-left: 20px;
            }
          }
          .el-tree-node.is-current > .el-tree-node__content {
            background-color: #e6f7ff;
            border-right: 3px solid #1890ff;
            .el-tree-node__label,
            .el-tree-node__expand-icon {
              color: #1890ff;
            }
            .el-tree-node__expand-icon.is-leaf {
              color: transparent !important;
            }
          }
        }
      }
    }
    .right {
      min-width: 800px;
    }
  }
}
</style>
